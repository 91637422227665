import { createStore as reduxCreateStore } from 'redux'
import { loadState } from './localStorage'
import update from 'immutability-helper' // needed for nested state updates


// initial state is set from ./loadStorage.js if no state is found on the browser. a store.subscribe in gatsby-browser saves all actions to state/localstorage.
let persistedState = {
  cart: [],
  cartAnimation: true,
  miniCartAnimation: false,

  orderPrice: 0,
  stepper: 'cart',

  shippingName: '',
  shippingAddress1: '',
  shippingAddress2: '',
  shippingCity: '',
  shippingState: '',
  shippingZip: '',
  shippingCountry: 'US',
  shippingRates: {},
  shippingOption: 0,
  shippingID: 'PRINTFUL_SLOW',
  shippingAmount: 0,
  
  clientEmail: '',

  stripeToken: '',
  showBilling: false,
  billingName: '',
  billingAddress1: '',
  billingAddress2: '',
  billingCity: '',
  billingState: '',
  billingZip: '',
  billingCountry: '',

  menu: false,
  paymentModal: false,
  sizeChartModal: false,
  sizeChartSetting: 'inches',

  productColor: '1',
  productQuantity: 1,
  selectedColor: 'blue',
}

if (typeof window !== `undefined`) {
  persistedState = loadState()
}

const reducer = (state = persistedState, action) => {
  switch (action.type) {
    case 'UPDATE_STATE':
      return {
        ...state,
        [action.item]: action.value,
      }
      
    case 'UPDATE_COUNTRY':
      return {
        ...state,
        [action.item]: action.value,
      }
    case 'ADD_TO_CART':
      return {
        ...state,
        cart: state.cart.concat(action.item),
      }
    
    // https://stackoverflow.com/questions/42605545/whats-the-advantage-of-using-splice-from-immutability-helper-over-filter-to
    //// had to convert action.key to const for it work, couldn't pass it in directly to splice.
    case 'REMOVE_FROM_CART':
    const index = action.key 
    return update(state, {
      cart: {$splice: [[index, 1]]}
    })
    

    case 'EMPTY_CART':
      return {
        ...state,
        cart: [],
      }

    // https://stackoverflow.com/questions/41157224/redux-update-nested-state-array uses 
    case 'UPDATE_CART_ITEM':
    return update(state, {
          cart: {
            [action.key] : {
              [action.item] : {$set : action.value}
            }
          } 
    })

    case 'UPDATE_SHIPPING_RATE':
      return {
        ...state,
        shippingRates: action.rates,
      }
    case 'REMOVE_SHIPPING_RATES':
      return {
        ...state,
        shippingRates: {},
        shippingAmount: 0,
      }
    case 'UPDATE_TOTALS':
      // console.log('this is the problem')
      let price = 0
      state.cart.forEach(element => {
        price = Number(element.price) * Number(element.qty) + Number(price)
      })
      price = (price / 100).toFixed(2)
      return {
        //totals calc per line is done on order submit, plus shipping, plus tax when need be and grand total
        ...state,
        orderPrice: price,
      }
      // break
    case 'STEPPER_STATE':
      return {
        ...state,
        stepper: action.stepper,
      }
    case 'MENU_STATE':
      return {
        ...state,
        menu: !state.menu,
      }
    default:
      return state
  }
}

const createStore = () => reduxCreateStore(reducer, persistedState)

export default createStore
