import React from 'react'
// import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { saveState } from './src/state/localStorage'

import createStore from './src/state/createStore'

const store = createStore()

store.subscribe(() => {
  saveState(store.getState())
})

// commented changes were made for gatsby 2.0.6 support on deprecated API call
// export const replaceRouterComponent = ({ history }) => {
export const wrapRootElement = ({ element }) => {
  // const ConnectedRouterWrapper = ({ children }) => (
  const ConnectedRootElement = (
    <Provider store={store}>
      {/* <Router history={history}>{children}</Router> */}
      {element}
    </Provider>
  )

  // return ConnectedRouterWrapper
  return ConnectedRootElement
}
