const initialState = {
      cart: [],
      cartAnimation: true,
      miniCartAnimation: false,
    
      orderPrice: 0,
      stepper: 'cart',
    
      shippingName: '',
      shippingAddress1: '',
      shippingAddress2: '',
      shippingCity: '',
      shippingState: '',
      shippingZip: '',
      shippingCountry: 'US',
      shippingRates: {},
      shippingOption: 0,
      shippingID: 'PRINTFUL_SLOW',
      shippingAmount: 0,
      
      clientEmail: '',

      stripeToken: '',
      showBilling: false,
      billingName: '',
      billingAddress1: '',
      billingAddress2: '',
      billingCity: '',
      billingState: '',
      billingZip: '',
      billingCountry: '',

      menu: false,
      paymentModal: false,
      sizeChartModal: false,
      sizeChartSetting: 'inches',

      productColor: '1',
      productQuantity: 1,
      selectedColor: 'blue',
    }



export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state')
    if (serializedState === null) {
      return initialState
    }
    return JSON.parse(serializedState)
  } catch (err) {
      console.log(err)
    return initialState
  }
}

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('state', serializedState)
  } catch (err) {
    console.log(err)
  }
}
